<template>
  <v-card class="d-flex no-wrap " color="grey lighten-2" flat tile>
    <v-layout row wrap color="grey lighten-2" flat tile>
      <v-flex xs5 color="grey lighten-2 pr-3" flat>
        <v-card outlined flat class="ma-5 order-1 pa-3">
          <v-label>
            <p class="subtitle-2 text-center teal--text mt-5">
              {{ " " }}
            </p>
          </v-label>

          <v-divider class="divider"></v-divider>
          <v-simple-table fixed-header class="pb-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="primary--text"><b>Full Name</b></td>
                  <td>{{ staff.fullName }}</td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Email</b></td>
                  <td>{{ staff.email }}</td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Gender</b></td>
                  <td>{{ staff.gender && staff.gender.name }}</td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Birth Date</b></td>
                  <td>{{ staff.birthdate }}</td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Age</b></td>
                  <td>{{ staff.age }}</td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Phone Number</b></td>
                  <td>{{ staff.phone_number }}</td>
                </tr>
                <!-- <tr>
                  <td><b>Registration Reason</b></td>
                  <td>
                    {{ staff.staff.registrationReasons }}
                  </td>
                </tr> -->
                <!-- <tr>
                  <td><b>Registration Type</b></td>
                  <td>
                    {{ staff.staff.registrationType }}
                  </td>
                </tr> -->

                <tr>
                  <td class="primary--text"><b>Center</b></td>
                  <td>{{ staff.location && staff.location.name }}</td>
                </tr>
                <tr>
                  <td class="primary--text"><b>NIDA ID</b></td>
                  <td>
                    {{ staff.NIN }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Staff Category</b></td>
                  <td>
                    {{
                      staff.teaching_category && staff.teaching_category.name
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Marital Status</b></td>
                  <td>
                    {{ staff.married ? "YES" : "NO" }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Number of Children</b></td>
                  <td>
                    {{ staff.number_children }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>
      <v-flex xs7 color="grey lighten-2" flat>
        <v-card outlined flat class="mt-5 mr-5 order-1">
          <v-label>
            <p
              class="subtitle-2 text-center primary--text font-weight-bold mt-5"
            >
              Staff Residence
            </p>
          </v-label>

          <v-divider class="divider"></v-divider>
          <v-simple-table fixed-header class="pb-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="primary--text"><b>Residence Region</b></td>
                  <td>
                    {{ staff.domicile_region && staff.domicile_region.name }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Residence Council</b></td>
                  <td>
                    {{
                      staff.domicile_district && staff.domicile_district.name
                    }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Permanent Address</b></td>
                  <td>
                    {{ staff.physical_address }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-layout column justify-space-between class="order-2  mr-5 mt-5 mb-5">
          <v-card>
            <v-row no-gutters>
              <v-col>
                <v-card class="pa-2" outlined tile>
                  <v-label>
                    <p
                      class="subtitle-2 text-center primary--text  font-weight-bold mt-5"
                    >
                      Staff Roles
                    </p>
                  </v-label>

                  <v-divider class="divider"></v-divider>
                  <v-simple-table fixed-header class="pb-5">
                    <template v-slot:default fixed-header class="pb-5">
                      <thead>
                        <tr>
                          <th class="text-left primary--text">
                            Name
                          </th>
                          <th class="text-left primary--text">
                            Descriptions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="role in staff.roles" :key="role.id">
                          <td>
                            <b>{{ role.name }}</b>
                          </td>
                          <td>{{ role.description }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  name: "StaffPersonalInformation",
  props: {
    staff: { type: Object },
  },
};
</script>
