import * as console from 'console';
<template>
  <div class="perfornal-employment">
    <div class="text--disabled">
      {{ tabTitle }}

      <hr style="width:200px" />
    </div>

    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" lg="2" md="2" sm="12">
              <v-text-field
                label="Personal File"
                v-model="formData.file_no"
                required
                :rules="[isValid.required, isValid.existsFileNo]"
                :loading="isValidatingData"
                @change.native="checkExistFileNa($event.target.value)"
                :messages="existsFileNaMessage"
                type="number"
                class="_input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12">
              <v-text-field
                label="TSC No"
                v-model="formData.tsc_no"
                :rules="[isValid.existsTSC]"
                :loading="isValidatingData"
                @change.native="checkExistsTSC($event.target.value)"
                :messages="existsTSCMessage"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <DatePicker
                v-model="formData.appointment_date"
                :label="'First Appointment Date'"
                :minDate="$mixins.minDate(60)"
                :maxDate="$mixins.maxDate(0)"
                :yearPicker="true"
                :required="true"
                :show-current="true"
                :requiredMessage="'You must select First Appointment Date'"
              />
            </v-col>

            <v-col cols="12" lg="2" md="2" sm="12">
              <DatePicker
                v-model="formData.approval_date"
                :label="'Approval  Date'"
                :yearPicker="true"
                :readonly="!formData.appointment_date"
                :minDate="$mixins.addDates(formData.appointment_date, 0, 0, 1)"
                :maxDate="$mixins.maxDate(0)"
                :show-current="true"
              />
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <DatePicker
                v-model="formData.last_promotion_date"
                :label="' Date of Last Promotion'"
                :minDate="formData.approval_date"
                :maxDate="$mixins.maxDate(0)"
                :yearPicker="true"
                :required="true"
                :requiredMessage="'You must enter Date of Last Promotion'"
                :show-current="true"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="3" md="3" sm="12">
              <v-select
                label="First Appointment Education"
                :items="designations"
                :item-text="'descriptions'"
                :rules="[isValid.required]"
                v-model="formData.employment_education"
                @change="loadSalaryScales($event)"
                return-object
                required
                v-uppercase
              ></v-select>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="12">
              <v-autocomplete
                label="First Appointment Designation"
                :items="educationDesignations"
                :item-text="'title_en'"
                :rules="[isValid.required]"
                v-model="formData.appointment_designation"
                @change="loadCurrentDesignationsScales()"
                return-object
                required
                v-uppercase
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="12">
              <v-autocomplete
                label="Current Designation"
                :items="designationScales"
                :item-text="'title_en'"
                :rules="[isValid.required]"
                v-model="formData.current_designation"
                @change="prepareSalaryScale($event)"
                return-object
                required
                v-uppercase
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="12">
              <v-autocomplete
                label="Teaching Category"
                :items="teachingCategories"
                :item-text="'name'"
                :rules="[isValid.required]"
                v-model="formData.staff_category"
                return-object
                required
                v-uppercase
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="12">
              <v-text-field
                label="Select Salary Scale"
                :rules="[isValid.required]"
                v-model="formData.staff_salary_scale"
                return-object
                required
                v-uppercase
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="12">
              <v-select
                label="Select Salary Step"
                :items="salarySteps"
                :item-text="'name'"
                :rules="[isValid.required]"
                v-model="formData.salary_step"
                @change="prepareSalaryScaleName($event)"
                return-object
                required
                v-uppercase
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" lg="4" md="4" sm="12">
              <v-autocomplete
                label="staff Category"
                :items="staffCategories"
                :item-text="'name'"
                v-model="formData.teaching_category"
                return-object
                required
                v-uppercase
                :rules="[isValid.required]"
              ></v-autocomplete>
            </v-col> -->
          </v-row>
          <v-card-actions class="md-8">
            <v-btn
              tile
              color="primary"
              @click="previous(2)"
              :disabled="activeStep === 0"
            >
              <v-icon left>mdi-skip-backward</v-icon>
              Previous</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              tile
              color="success"
              @click="add"
              :loading="loading"
              :disabled="!valid"
            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              Finish</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      required: false,
    },

    dialog: {
      type: Boolean,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
    },

    searchOptions: {
      type: Function,
      required: true,
    },

    loadSalaryScalesData: {
      type: Function,
      required: true,
    },

    loadDesignationsScalesData: {
      type: Function,
      required: true,
    },

    save: {
      type: Function,
      required: true,
    },
    genders: {
      type: Array,
      required: true,
    },

    designationScales: {
      type: Array,
      required: true,
    },

    universities: {
      type: Array,
      required: true,
    },

    educationDesignations: {
      type: Array,
      required: true,
    },

    tabTitle: {
      type: String,
      required: false,
    },
    validated: {
      type: Function,
      required: false,
    },

    activeStep: {
      type: Number,
      required: true,
    },

    next: {
      type: Function,
      required: true,
    },

    previous: {
      type: Function,
      required: true,
    },
  },

  name: "StaffEmploymentDetails",

  data: () => ({
    valid: true,

    existsFIVMessage: "",
    existsFVIMessage: "",
    existsNidaMessage: "",
    existsFileNaMessage: "",
    existsTSCMessage: "",
    isValidating: false,
    salaryScales: [],
    salaryGrades: [],
    salarySteps: [],
    designations: [],
    teachingCategories: [],
    // educationDesignations: [],
    EXISTS_API: "/api/v1/users/exists",
    isValid: {
      name: (v) => !!v || " Name required",
      required: (v) => !!v || " Field required",
      /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',

      existsNida: () => true || this.existsNidaMessage,
      existsFileNo: () => true || this.existsFileNaMessage,
      existsTSC: () => true || this.existsTSCMessage,
    },
    location: {},
  }),

  methods: {
    prepareSalaryScale(data) {
      this.formData.salary_step = null;
      this.formData.staff_salary_scale = data.scale;
      this.formData.salary_scale = data;
    },

    prepareSalaryScaleName(data) {
      this.formData.salary_scale_name =
        this.formData.salary_scale.scale + data.name;
    },

    sScales() {
      this.$mixins.fetchByCategory("SALARY_SCALE").then((response) => {
        this.salaryScales = response;
      });
    },

    sGrades() {
      this.$mixins.fetchByCategory("SALARY_GRADE").then((response) => {
        this.salaryGrades = response;
      });
    },

    sSteps() {
      this.$mixins.fetchByCategory("SALARY_SCALE_STEP").then((response) => {
        this.salarySteps = response;
      });
    },

    appointmentDesignation() {
      this.$mixins
        .fetchByCategory("APPOINTMENT_DESIGNATION")
        .then((response) => {
          this.designations = response;
        });
    },

    loadSalaryScales(data) {
      this.formData.salary_scale_name = null;
      this.formData.appointment_designation = null;
      this.formData.current_designation = null;
      this.formData.salary_scale = null;
      this.formData.salary_step = null;

      this.loadSalaryScalesData({
        is_first_appointment: 1,
        professional_category_id: data.id,
      });
    },

    loadCurrentDesignationsScales() {
      this.loadDesignationsScalesData({
        professional_category_id: this.formData.employment_education.id,
      });
    },
    // currentDesignation() {
    //   this.$mixins.fetchByCategory("CURRENT_DESIGNATION").then((response) => {
    //     this.educationDesignations = response;
    //   });
    // },

    getTeachingCategories() {
      this.$mixins.fetchByCategory("TEACHER_CATEGORIES").then((response) => {
        this.teachingCategories = response;
      });
    },

    add() {
      let payload = { ...this.formData };
      payload.completion_percentage = 33;
      this.save(payload);
    },

    close() {
      this.closeDialog();
      this.location = {};
    },
    search(data) {
      this.searchRoles(data);
    },
    setActive(selection) {
      this.location = selection.length > 0 ? selection[0] : { name: null };
    },

    async checkExistFileNa(data) {
      if (!data) {
        this.isValid.existsFileNo = false;
        this.existsFileNaMessage = "";

        return;
      }
      this.isValidating = true;
      let payload = {
        file_no: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsFileNo = false;
          this.existsFileNaMessage = `File Number already in use at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsFileNo = true;
          this.existsFileNaMessage = "";
        }
      });
    },

    async checkExistsTSC(data) {
      if (!data) {
        this.isValid.existsTSC = true;
        this.existsTSCMessage = "";

        return;
      }
      this.isValidating = true;
      let payload = {
        tsc_no: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsTSC = false;
          this.existsTSCMessage = `TSC already in use at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsTSC = true;
          this.existsTSCMessage = "";
        }
      });
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
    selected() {
      return this.formData &&
        this.formData.location &&
        Object.keys(this.location).length === 0
        ? this.formData.location
        : this.location;
    },

    isHOS() {
      return this.user?.location?.level?.id === 5 ? true : false;
    },

    isValidatingData() {
      return this.isValidating ? true : false;
    },
  },
  mounted() {
    if (this.formData && this.formData.employment_education) {
      this.loadSalaryScalesData({
        is_first_appointment: 1,
        professional_category_id: this.formData.employment_education.id,
      });

      this.loadDesignationsScalesData({
        professional_category_id: this.formData.employment_education.id,
      });
    }

    this.sScales();

    this.sGrades();

    this.sSteps();
    this.appointmentDesignation();
    // this.currentDesignation();
    this.getTeachingCategories();
  },
  watch: {
    valid(oldValue, newValue) {
      this.validated(newValue);
    },
  },
};
</script>

<style scoped>
.no-scroll {
  overflow: hidden;
}

._input >>> input::-webkit-outer-spin-button,
._input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
