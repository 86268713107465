<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      width="900px"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title
            class="white--text pl-2 d-none d-md-flex d-lg-none d-none d-lg-flex "
          >
            <span
              class="white--text d-none d-md-flex d-lg-none d-none d-lg-flex "
              >{{ title }} User</span
            >
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="closeDialog">
              <v-icon small right dark>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-stepper v-model="e1" non-linear>
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              :rules="[(value) => !!isValid.validStaffInfo]"
            >
              Staff Personal Information
              <small v-if="!isValid.validStaffInfo" class="red--text">
                Some Data validation failed
              </small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="2"
              :complete="e1 > 2"
              :rules="[(value) => !!isValid.validEducationInfo]"
            >
              Staff Education Information
              <small v-if="!isValid.validEducationInfo" class="red--text">
                Some Data validation failed
              </small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="3"
              :complete="e1 > 3"
              :rules="[(value) => !!isValid.validEmploymentInfo]"
            >
              Employment Information
              <small v-if="!isValid.validEmploymentInfo" class="red--text">
                Some Data validation failed
              </small>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <perfect-scrollbar>
              <v-stepper-content step="1" complete>
                <staff-personal-information
                  :formData="formData"
                  :roles="roles"
                  :user="user"
                  :searchOptions="searchOptions"
                  :save="savePersonalInformations"
                  :genders="genders"
                  :dialog="dialog"
                  :tabTitle="'Personal Information / Details'"
                  :validated="checkValidationStaffinfo"
                  :activeStep="activeStep"
                  :next="next"
                  :previous="previous"
                  :loading="loading"
                  :staffCategories="staffCategories"
                ></staff-personal-information>
              </v-stepper-content>

              <v-stepper-content step="2" complete>
                <staff-education-information
                  :formData="formData"
                  :roles="roles"
                  :user="user"
                  :searchOptions="searchOptions"
                  :save="saveEducationInformations"
                  :genders="genders"
                  :dialog="dialog"
                  :universities="universities"
                  :tabTitle="'Education Information Details'"
                  :validated="checkValidationEducationinfo"
                  :elimu="elimu"
                  :taaluma="taaluma"
                  :kozi="kozi"
                  :masomo="masomo"
                  :masomoKufundishia="masomoKufundishia"
                  :search-teaching-subjects="searchTeachingSubjects"
                  :search-proffessional-subjects="searchProffessionalSubjects"
                  :activeStep="activeStep"
                  :next="next"
                  :previous="previous"
                  :loading="loading"
                ></staff-education-information>
              </v-stepper-content>

              <v-stepper-content step="3" complete>
                <staff-employment-details
                  :formData="formData"
                  :roles="roles"
                  :user="user"
                  :searchOptions="searchOptions"
                  :save="saveEmploymentInformations"
                  :genders="genders"
                  :dialog="dialog"
                  :universities="universities"
                  :tabTitle="'Education Information Details'"
                  :validated="checkValidationEmploymentinfo"
                  :loadSalaryScalesData="loadSalaryScalesData"
                  :educationDesignations="educationDesignations"
                  :loadDesignationsScalesData="loadDesignationsScalesData"
                  :designationScales="designationScales"
                  :activeStep="activeStep"
                  :next="next"
                  :previous="previous"
                  :loading="loading"
                ></staff-employment-details>
              </v-stepper-content>
            </perfect-scrollbar>
          </v-stepper-items>
        </v-stepper>

        <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <DialogButtons
              :close="close"
              :save="add"
              :loading="loading"
              :valid="valid"
              :title="title"
            />
          </v-card-actions> -->
        <!-- <pre>{{ formData }}</pre> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
import StaffPersonalInformation from "./StaffComponents/StaffPersonalInformation.vue";
import StaffEducationInformation from "./StaffComponents/StaffEducationInformation.vue";
import StaffEmploymentDetails from "./StaffComponents/StaffEmploymentDetails.vue";
export default {
  components: {
    StaffPersonalInformation,
    StaffEducationInformation,
    StaffEmploymentDetails,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    searchOptions: {
      type: Function,
      required: true,
    },

    closeDialog: {
      type: Function,
      required: true,
    },

    save: {
      type: Function,
      required: true,
    },

    savePersonalInformation: {
      type: Function,
      required: true,
    },

    saveEducationInformation: {
      type: Function,
      required: true,
    },
    saveEmploymentInformation: {
      type: Function,
      required: true,
    },

    checkLoadingStatus: {
      type: Function,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    universities: {
      type: Array,
      required: true,
    },

    educationDesignations: {
      type: Array,
      required: true,
    },

    designationScales: {
      type: Array,
      required: true,
    },

    masomo: {
      type: Array,
      required: false,
    },

    elimu: {
      type: Array,
      required: false,
    },

    taaluma: {
      type: Array,
      required: false,
    },
    staffCategories: {
      type: Array,
      required: false,
    },

    kozi: {
      type: Array,
      required: false,
    },

    masomoKufundishia: {
      type: Array,
      required: false,
    },

    searchTeachingSubjects: {
      type: Function,
      required: false,
    },

    searchProffessionalSubjects: {
      type: Function,
      required: false,
    },

    loadSalaryScalesData: {
      type: Function,
      required: true,
    },

    loadDesignationsScalesData: {
      type: Function,
      required: true,
    },
  },
  name: "StaffDialog",
  data: () => ({
    step: 1,
    valid: true,

    isValid: {
      validStaffInfo: () => true || "Invalid input",
      validEducationInfo: () => true || "Invalid input",
      validEmploymentInfo: () => true || "Invalid input",
    },
    location: {},

    e1: 1,
    activeStep: 0,
    steps: 3,
  }),
  methods: {
    next(step) {
      this.activeStep++, (this.e1 = step);
    },

    previous(step) {
      this.activeStep--, (this.e1 = step);
    },

    add() {
      this.formData.location =
        this.isHOS === true ? this.user.location : this.selected;

      this.save(this.formData);
    },

    close() {
      this.closeDialog();
      this.location = {};
    },

    checkValidationStaffinfo(data) {
      this.isValid.validStaffInfo = data === true ? false : true;
    },

    checkValidationEducationinfo(data) {
      this.isValid.validEducationInfo = data === true ? false : true;
    },

    checkValidationEmploymentinfo(data) {
      this.isValid.validEmploymentInfo = data === true ? false : true;
    },

    savePersonalInformations(data) {
      this.savePersonalInformation(data).then((response) => {
        if (response === true) {
          this.next(2);

          this.checkLoadingStatus(false);
        }
      });
    },

    saveEducationInformations(data) {
      this.saveEducationInformation(data).then((response) => {
        if (response === true) {
          this.next(3);
          this.checkLoadingStatus(false);
        }
      });
    },
    saveEmploymentInformations(data) {
      this.saveEmploymentInformation(data).then((response) => {
        if (response === true) {
          this.next(1);
          this.checkLoadingStatus(false);
        }
      });
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
    selected() {
      return this.formData &&
        this.formData.location &&
        Object.keys(this.location).length === 0
        ? this.formData.location
        : this.location;
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
.ps {
  height: 400px;
}
</style>
