import axios from "axios";
const ENDPOINT = "/api/v1/universities";

const get = async payload => {
  return axios.get(ENDPOINT, { params: payload });
};

const save = async payload => {
  return await axios.post(ENDPOINT, payload);
};


const remove = async payload => {
  return axios.delete(ENDPOINT + "/" + payload);
};

export { get, save, remove };
