<template>
  <v-card class="d-flex no-wrap " color="grey lighten-2" flat tile>
    <v-layout row wrap color="grey lighten-2" flat tile>
      <v-flex xs5 color="grey lighten-2 pr-3" flat>
        <v-card outlined flat class="ma-5 order-1 pa-3">
          <v-label>
            <p
              class="subtitle-2 text-center primary--text font-weight-bold mt-5"
            >
              Education
            </p>
          </v-label>

          <v-divider class="divider"></v-divider>
          <v-simple-table fixed-header class="pb-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="primary--text"><b>Form IV Index Number</b></td>
                  <td>{{ staff.form_iv_index }}</td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Form VI Index Number </b></td>
                  <td>{{ staff.form_vi_index }}</td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Education Level</b></td>
                  <td>
                    {{ staff.education_level && staff.education_level.name }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Proffessional Level</b></td>
                  <td>
                    {{
                      staff.professional_level && staff.professional_level.name
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>
      <v-flex xs7 color="grey lighten-2" flat>
        <v-card outlined flat class="mt-5 mr-5 order-1">
          <v-label>
            <p
              class="subtitle-2 text-center primary--text font-weight-bold mt-5"
            >
              University Education
            </p>
          </v-label>

          <v-divider class="divider"></v-divider>
          <v-simple-table fixed-header class="pb-5 mb-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="primary--text"><b>Graduated University</b></td>
                  <td>
                    {{ staff.university && staff.university.name }} -({{
                      staff.university && staff.university.acronym
                    }})
                  </td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Graduation Year</b></td>
                  <td>
                    {{ staff.graduation_year }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Undertaken Course</b></td>
                  <td>
                    {{
                      staff.professional_course &&
                        staff.professional_course.name
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text">
                    <b>University/College Region or Country</b>
                  </td>
                  <td>
                    {{ staff.university && staff.university.region }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>

      <v-flex xs12 color="grey lighten-2" flat>
        <v-layout column justify-space-between class="order-2  mr-5 mt-5 mb-5">
          <v-card>
            <v-row no-gutters>
              <v-col sm="6" md="6" offset-md="2" lg="6" offset-lg="0">
                <v-card class="pa-2" outlined tile>
                  <v-label>
                    <p
                      class="subtitle-2 text-center primary--text  font-weight-bold mt-5"
                    >
                      Proffessional Subjects
                    </p>
                  </v-label>

                  <v-divider class="divider"></v-divider>
                  <v-simple-table fixed-header class="pb-5">
                    <template v-slot:default fixed-header class="pb-5">
                      <thead>
                        <tr>
                          <th class="text-left primary--text">
                            Code
                          </th>

                          <th class="text-left primary--text">
                            Name
                          </th>
                          <th class="text-left primary--text">
                            Abbreviation
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="subject in staff.proffesional_subjects"
                          :key="subject.id"
                        >
                          <td>
                            <b>{{ subject.code }}</b>
                          </td>

                          <td>
                            <b>{{ subject.name }}</b>
                          </td>
                          <td>{{ subject.abbreviation }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <v-col sm="6" md="6" offset-md="2" lg="6" offset-lg="0">
                <v-card class="pa-2" outlined tile>
                  <v-label>
                    <p
                      class="subtitle-2 text-center primary--text  font-weight-bold mt-5"
                    >
                      Teaching Subjects
                    </p>
                  </v-label>

                  <v-divider class="divider"></v-divider>
                  <v-simple-table fixed-header class="pb-5">
                    <template v-slot:default fixed-header class="pb-5">
                      <thead>
                        <tr>
                          <th class="text-left primary--text">
                            Code
                          </th>

                          <th class="text-left primary--text">
                            Name
                          </th>
                          <th class="text-left primary--text">
                            Abbreviation
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="subject in staff.teaching_subjects"
                          :key="subject.id"
                        >
                          <td>
                            <b>{{ subject.code }}</b>
                          </td>

                          <td>
                            <b>{{ subject.name }}</b>
                          </td>
                          <td>{{ subject.abbreviation }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  name: "StaffEducationInformation",
  props: {
    staff: { type: Object },
  },
};
</script>
