<template>
  <div class="users">
    <v-card-actions class="pa-0">
      <page-title :title="title" />

      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-autocomplete
          v-model="params.status"
          :items="['COMPLETED', 'INCOMPLETE']"
          @change="loadStatus($event)"
          class="pr-2 pt-8 my-input  d-sm-flex"
          outlined
          dense
          clearable
          :label="'View Status'"
        ></v-autocomplete>
      </v-col>
      <add-button
        :create-function="downloadProgress"
        :icon="'mdi-download'"
        :title="'Entry Progress(xls)'"
      />

      <add-button
        :create-function="downloadTange"
        :icon="'mdi-download'"
        :title="'download tange(xls)'"
      />
    </v-card-actions>
    <!-- <eFacilities :setActive="setActive" /> -->

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :server-items-length="params.total"
      :options.sync="pagination"
      :items-per-page="params.size"
      :single-expand="true"
      class="elevation-1"
      :no-data-text="'Data not available in this resource'"
      :footer-props="{
        'items-per-page-options': [20, 50, 100, 300, 500, -1],
      }"
    >
      <template v-slot:top>
        <v-card-title>
          <v-spacer></v-spacer>

          <v-row
            v-if="
              user &&
                user.location &&
                user.location.level &&
                user.location.level.id != 5
            "
          >
            <v-col cols="12" sm="12" md="2" lg="2">
              <v-select
                label="Filter by  level"
                v-model="filter.level"
                :items="adminLevels"
                :item-text="'name'"
                :item-value="'id'"
                class
                clearable
                flat
                hide-details
                dense
                prepend-icon="mdi-magnify"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="2" lg="2">
              <v-select
                label="Filter by level Type"
                v-model="filter.school_type_id"
                :items="schoolLevels"
                :item-text="'name'"
                :item-value="'id'"
                class
                clearable
                flat
                hide-details
                dense
                prepend-icon="mdi-magnify"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="2" lg="2">
              <v-select
                label="Filter by Ownership"
                v-model="filter.ownership_id"
                :items="schoolOwnership"
                :item-text="'name'"
                :item-value="'id'"
                class
                clearable
                flat
                hide-details
                dense
                prepend-icon="mdi-magnify"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="3" lg="3">
              <v-text-field
                @change.native="init"
                v-model="filter.search"
                class
                clearable
                flat
                hide-details
                dense
                prepend-icon="mdi-magnify"
                outlined
                label="Enter search String"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
              <v-text-field
                v-model="selectedLoc"
                class
                flat
                hide-details
                maxlength="8"
                :label="'Search by Location'"
                prepend-icon="mdi-magnify"
                @click="searchUserByLocation"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="2" lg="2">
              <add-button
                :create-function="downloadProgress"
                :icon="'mdi-download'"
                :title="'Filter Report(xls)'"
              />
            </v-col> -->
          </v-row>

          <!-- <v-col cols="6" sm="12" md="4" class="pl-4">
            <SearchField
              :search-function="searchUser"
              :prepend-icon="'mdi-magnify'"
              :label="'Search Data'"
              :append-icon="''"
            />
          </v-col> -->
        </v-card-title>
      </template>

      <template v-slot:item="{ item, isExpanded, expand }">
        <tr>
          <td>{{ item.index }}</td>
          <td>
            <v-list-item-avatar
              style="height: 80px; min-width: 80px; width: 80px;"
            >
              <v-img
                :src="
                  item.photo
                    ? item.photo
                    : require('../../assets/images/user.png')
                "
                :alt="item.fullName"
              ></v-img>
            </v-list-item-avatar>
          </td>
          <td>{{ item.fullName }}</td>
          <td>{{ item.gender ? item.gender.name : "" }}</td>
          <td>{{ item.age }}</td>
          <td>{{ item.file_no }}</td>
          <td>{{ item.tsc_no }}</td>
          <td>{{ item.cheque_number }}</td>
          <td>
            {{
              item.staff_education_details &&
                item.staff_education_details.professional_level &&
                item.staff_education_details.professional_level.name
            }}
          </td>

          <!-- <td>
            {{ item.employment_education && item.employment_education.name }}
          </td> -->
          <td>{{ item.birthdate }}</td>
          <td>{{ item.appointment_date }}</td>
          <td>{{ item.retire_date }}</td>
          <td>
            {{ item.current_designation && item.current_designation.title_en }}
          </td>

          <td>{{ item.location.name }}</td>

          <td>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="7"
              :value="item.completion_percentage"
              :color="item.color"
            >
              {{ item.completion_percentage }}
            </v-progress-circular>
          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="!isExpanded"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="expand(!isExpanded)"
                >
                  mdi-library-books
                </v-icon>

                <v-icon
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="expand(!isExpanded)"
                >
                  mdi-book-open-page-variant
                </v-icon>
              </template>
              <span>View Staff Profile Data</span>
            </v-tooltip>
          </td>
          <td>
            <v-menu bottom left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          @click="resetPassword(item)"
                          color="primary"
                        >
                          mdi-lock-reset
                        </v-icon>
                      </template>
                      <span>Reset Password</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          @click="openDialog(item)"
                          color="primary"
                        >
                          mdi-pencil-box-multiple
                        </v-icon>
                      </template>
                      <span>Update</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          @click="deleteData(item)"
                          color="red"
                        >
                          mdi-delete-circle
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>

      <!-- Here starts th profile component -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pb-5">
          <div class="ml-5 pt-5">
            <b>Staff Profile :</b>

            <div class="content-wrapper">
              <v-card class="pr-1 mr-1 summary" outlined elevation="1">
                <v-list-item three-line>
                  <div class="photo-content">
                    <v-list-item-avatar
                      style="height: 100px; min-width: 100px; width: 100px;     display: flex;"
                      class="mx-auto"
                    >
                      <v-img
                        :src="
                          item.photo
                            ? item.photo
                            : require('../../assets/images/user.png')
                        "
                        :alt="item.fullName"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-card-text>
                        <v-row>
                          <div class="text--center">
                            <v-rating
                              :value="item.percRate"
                              color="amber"
                              dense
                              half-increments
                              readonly
                              size="14"
                            ></v-rating>
                          </div>

                          <div class="grey--text ms-1">
                            {{ item.percRate }}
                          </div>

                          <div class="grey--text ms-1 text-center">
                            (Profile Completion)
                          </div>
                        </v-row>
                      </v-card-text>

                      <div
                        class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                      >
                        Member Information
                      </div>
                      <v-divider class="mx-4"></v-divider>

                      <div class="ml-0 mb-0">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Staff Full name
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.fullName }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Gender
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.gender.name }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Date of Birth
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.birthdate }}(<small
                            >{{ item.age }} years</small
                          >)
                        </v-list-item-subtitle>
                      </div>
                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Cheque Number
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.cheque_number }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          TSC Number
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.tsc_no }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          File Number
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.file_no }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          NIDA ID
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.NIN }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Station
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.location.name }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Mobile Phone Number
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.phone_number }}
                        </v-list-item-subtitle>
                      </div>

                      <!-- <div class="ml-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Physical Address
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          {{ item.physical_address }}
                        </v-list-item-subtitle>
                      </div>

                      <div class="mn-2 pt-0 ">
                        <v-list-item-title
                          class="my-4 text-subtitle-2 text-center mb-1 font-weight-bold  primary--text"
                        >
                          Place of Domicile Data
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          Region: {{ item.domicile_region.name }} <br />
                          District: {{ item.domicile_district.name }}
                        </v-list-item-subtitle>
                      </div> -->
                    </v-list-item-content>
                  </div>
                </v-list-item>
              </v-card>

              <v-card class="all_data" outlined>
                <v-sheet elevation="6">
                  <v-tabs
                    v-model="tab"
                    color="basil"
                    next-icon="mdi-arrow-right-bold-box-outline"
                    prev-icon="mdi-arrow-left-bold-box-outline"
                    show-arrows
                    grow
                    background-color="primary"
                    centered
                    dark
                    icons-and-text
                  >
                    <!-- icons-and-text -->
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab href="#staff-personal-information">
                      STAFF PERSONAL INFORMATION
                    </v-tab>
                    <v-tab href="#staff-education-information">
                      STAFF EDUCATION INFORMATION
                    </v-tab>
                    <v-tab href="#staff-employment-information">
                      STAFF EMPLOYMENT DATA
                    </v-tab>
                    <v-tab href="#staff-leave">
                      LEAVE
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item :value="'staff-personal-information'">
                      <staff-personal-information
                        :staff="item"
                      ></staff-personal-information>
                    </v-tab-item>
                    <v-tab-item :value="'staff-education-information'">
                      <staff-education-information
                        :staff="item"
                      ></staff-education-information>
                    </v-tab-item>
                    <v-tab-item :value="'staff-employment-information'">
                      <staff-employment-information
                        :staff="item"
                      ></staff-employment-information>
                    </v-tab-item>
                    <v-tab-item :value="'staff-leave'">
                      NO LEAVE DATA AVAILABLE
                    </v-tab-item>
                  </v-tabs-items>
                </v-sheet>
              </v-card>
            </div>

            <!-- <v-data-table
                :headers="headerRoles"
                :items="item.roles"
                :items-per-page="item.roles ? item.roles.length : 20"
                hide-default-footer
                dense
              ></v-data-table> -->
          </div>
        </td>
      </template>
    </v-data-table>

    <floating-button
      :create-function="openDialog"
      :icon="'mdi-plus'"
      :title="''"
    />
    <!-- <CreateButton
      :create-function="openDialog"
      :icon="' mdi-view-grid-plus-outline'"
    />-->
    <StaffDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :roles="roles"
      :searchOptions="searchRoles"
      :genders="gender"
      :user="user"
      :universities="universities"
      :kozi="kozi"
      :elimu="elimu"
      :taaluma="taaluma"
      :masomo="masomo"
      :masomoKufundishia="masomoKufundishia"
      :searchTeachingSubjects="searchTeachingSubjects"
      :searchProffessionalSubjects="searchProffessionalSubjects"
      :loadSalaryScalesData="loadSalaryScalesData"
      :educationDesignations="educationDesignations"
      :designationScales="designationScales"
      :loadDesignationsScalesData="loadDesignationsScalesData"
      :savePersonalInformation="savePersonalInformation"
      :saveEducationInformation="saveEducationInformation"
      :saveEmploymentInformation="saveEmploymentInformation"
      :staffCategories="staffCategories"
      :checkLoadingStatus="checkLoadingStatus"
    />

    <userSearchDialog
      :title="'Search '"
      :userDialogOpen="userDialogOpen"
      :closeDialog="closeSearchDialog"
      :search="findUser"
      v-model="filter.location_id"
    />
    <confirm-delete-resolve ref="confirm" />
  </div>
</template>

<script>
import StaffDialog from "./Forms/StaffDialog";
import StaffPersonalInformation from "./Forms/StaffProfiles/StaffPersonalInformation";
import StaffEducationInformation from "./Forms/StaffProfiles/StaffEducationInformation";
import StaffEmploymentInformation from "./Forms/StaffProfiles/StaffEmploymentInformation";
import userSearchDialog from "./Forms/StaffDialogSearch";
import { user } from "./services";
import { get as fetchRoles } from "../roles/services";
import { get as getUniversities } from "../universities/services";
import { get as getSalaryScale } from "../salary-scales/services";
import { schoolData } from "../locations/services";
import { get as subjects } from "../subjects/services";
import { get as adminLevel } from "./../levels/services";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    StaffDialog,
    userSearchDialog,
    StaffPersonalInformation,
    StaffEducationInformation,
    StaffEmploymentInformation,
  },
  name: "StaffComponent",
  data() {
    return {
      tab: 0,
      roles: [],
      selected: {},
      gender: [],
      adminLevels: [],
      levels: [],
      schoolLevels: [],
      schoolOwnership: [],
      filter: {},
      datas: [],
      title: "Manage Staff",
      isEditing: false,
      formData: {},
      userLocation: {},
      dialog: false,
      isOpen: false,
      userDialogOpen: false,
      loading: false,
      dialogVisible: false,
      params: {},
      item: null,
      items: [],
      universities: [],
      educationDesignations: [],
      kozi: [],
      elimu: [],
      taaluma: [],
      masomo: [],
      designationScales: [],
      masomoKufundishia: [],
      staffCategories: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },

        {
          text: "",
          align: "start",
          sortable: false,
          value: "photo",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fullName",
          class: "primary--text",
        },
        {
          text: "Gender",
          align: "start",
          sortable: false,
          value: "gender",
          class: "primary--text",
        },

        {
          text: "Age",
          align: "start",
          sortable: false,
          value: "age",
          class: "primary--text",
        },

        {
          text: "File No",
          align: "start",
          sortable: false,
          value: "file_no",
          class: "primary--text",
        },

        {
          text: "TSCNo",
          align: "start",
          sortable: false,
          value: "tsc_no",
          class: "primary--text",
        },
        {
          text: "Cheq.No",
          align: "start",
          sortable: false,
          value: "checque_number",
          class: "primary--text",
        },
        {
          text: "Education",
          align: "start",
          sortable: false,
          value: "checque_number",
          class: "primary--text",
        },

        {
          text: "Date Birth",
          align: "start",
          sortable: false,
          value: "checque_number",
          class: "primary--text",
        },

        {
          text: "Appo. Date",
          align: "start",
          sortable: false,
          value: "",
          class: "primary--text",
        },

        {
          text: "Retire Date",
          align: "start",
          sortable: false,
          value: "",
          class: "primary--text",
        },

        {
          text: "Current Designation",
          align: "start",
          sortable: false,
          value: "current_designation",
          class: "primary--text",
        },

        {
          text: "Center",
          align: "start",
          sortable: false,
          value: "location.name",
          class: "primary--text",
        },
        {
          text: "Completion Percentage",
          align: "start",
          sortable: false,
          value: "",
          class: "primary--text",
        },

        {
          text: "Roles",
          align: "start",
          sortable: false,
          value: "roles",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
      headerRoles: [
        {
          text: "Role Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
    selectedLoc: {
      get() {
        return this.userLocation ? this.userLocation.name : null;
      },
      set(val) {
        // console.log(val)
        val;
      },
    },
  },

  methods: {
    getAdminLevels() {
      adminLevel().then((response) => {
        this.adminLevels = response.data[0].data;
      });
    },
    getLevel() {
      this.$mixins.fetchByCategory("EDUCATION_LEVELS").then((response) => {
        this.levels = response;
      });
    },

    getSchoolOwnership() {
      this.$mixins.fetchByCategory("SCHOOL_OWNERSHIP").then((response) => {
        this.schoolOwnership = response;
      });
    },

    getSchoolLevels() {
      this.$mixins.fetchByCategory("EDUCATION_LEVELS").then((response) => {
        this.schoolLevels = response;
      });
    },

    loadStatus(data) {
      if (data) {
        console.log(data.toLowerCase());

        // init({..this.})
      }
    },
    setColor(data) {
      let value = "";
      if (typeof data === "string") {
        value = parseInt(data);
      } else {
        value = data;
      }

      if (value === 0) {
        return "red";
      } else if (value === 34) {
        return "pink";
      } else if (value === 67) {
        return "#557CB9";
      } else if (value === 100) {
        return "teal";
      }
    },

    completionRate(data) {
      let value = "";
      if (typeof data === "string") {
        value = parseInt(data);
      } else {
        value = data;
      }

      return Math.round((value * 5) / 100);
    },

    getAge(data) {
      var age = new Date().getFullYear() - new Date(data).getFullYear();
      var m = new Date().getMonth() - new Date(data).getMonth();
      if (
        m < 0 ||
        (m === 0 && new Date().getDate() < new Date(data).getDate())
      ) {
        age = age - 1;
      }
      return age;
    },

    checkLoadingStatus(data) {
      this.loading = data;
    },
    reset() {
      this.dialogVisible = true;
    },
    closeResetDialog() {
      this.dialogVisible = false;
    },

    init(params) {
      this.loading = true;
      user
        .staff({
          ...params,
          ...this.filter,
        })
        .then((response) => {
          this.loading = false;
          // console.log('resp', response.data[0].total)
          this.params.total = response.data.total;
          this.items = response.data
            ? response.data.data
                .map((user) => ({
                  ...user,
                  ...user.staff_education_details,
                  ...user.staff_employment_details,
                  color: this.setColor(user.completion_percentage),
                  percRate: this.completionRate(user.completion_percentage),
                  age: this.getAge(user.birthdate),
                  staff_id: user.id,
                  id: user.id,
                  photo: user.photo
                    ? "data:image/png;base64," + user.photo
                    : null,
                  fullName:
                    user.first_name +
                    " " +
                    user.middle_name +
                    " " +
                    user.surname,
                }))
                // .sort(function(a, b) {
                //   return a.employment_education?.name >
                //     b.employment_education?.name
                //     ? -1
                //     : 1;
                // })
                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    vyuo() {
      getUniversities({ size: 500 }).then((result) => {
        this.universities = result.data.data;
      });
    },

    getSubjects(params) {
      subjects(params).then((result) => {
        this.masomo = result.data[0].data;
      });
    },

    getTeachingSubjects(params) {
      subjects(params).then((result) => {
        this.masomoKufundishia = result.data[0].data;
      });
    },

    searchUserByLocation(data) {
      this.userDialogOpen = true;
    },
    closeSearchDialog() {
      this.userDialogOpen = false;
    },
    textTruncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    openDialog(data = null) {
      this.dialog = true;

      if (this.user?.location?.level?.id === 5) {
        this.formData.location = this.user?.location;
      }
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.getRoles();
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },
    openUserDialog() {
      this.searchUserByLocation();
    },

    deleteObject() {
      this.loading = true;
      user
        .remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    async deleteData(data, item) {
      this.$refs.confirm
        .show({
          title: "Delete User",
          message: "Are you sure you want to delete this user ? ",
          loading: this.loading,
        })
        .then((result) => {
          if (result) {
            // this.deleteObject(item);
            user
              .remove(item)
              .then(() => {
                this.finishDataActions();
              })
              .catch((err) => {
                if (err) {
                  this.loading = false;
                }
              });
          } else {
            this.loading = false;
          }
        });
    },

    searchUser(search) {
      this.params.search = search ? search : null;
      this.init({
        // size: this.params.size,
        // total: this.params.total,

        ...this.params,
      });
    },

    findUser(data) {
      this.userLocation = data;
      let params = { location_id: data.id };
      this.filter.location_id = data.id;
      this.init(params);
      this.closeSearchDialog();
    },

    searchRoles(data) {
      this.getRoles({
        search: data ? data : null,
      });
    },

    searchTeachingSubjects(data) {
      this.getTeachingSubjects({
        search: data ? data : null,
      });
    },

    searchProffessionalSubjects(data) {
      this.getSubjects({
        search: data ? data : null,
      });
    },

    save(data) {
      this.loading = true;
      if (data.staff_id) {
        user
          .update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        user
          .create(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },

    savePersonalInformation(data) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        if (data.staff_id) {
          return user
            .update(data)
            .then(() => {
              resolve(true);
              this.loading = false;
              this.init({ ...this.params, user_id: data.staff_id });
            })
            .catch((err) => {
              if (err) {
                resolve(false);
                this.loading = false;
              }
            });
        } else {
          return user
            .create(data)
            .then((response) => {
              // console.log(response);
              this.formData.staff_id = response.data?.data?.id;
              resolve(true);
              this.loading = false;
              this.param.firstTimeCreated = true;
              this.init({ ...this.params });
            })
            .catch((err) => {
              if (err) {
                this.loading = false;
                resolve(false);
              }
            });
        }
      });
    },

    saveEducationInformation(data) {
      return new Promise((resolve, reject) => {
        this.loading = true;

        user
          .createUpdateStaffEducationInfo(data)
          .then(() => {
            this.loading = false;
            this.init({ ...this.params, user_id: data.staff_id });
            resolve(true);
          })
          .catch((err) => {
            if (err) {
              reject(true);
              this.loading = false;
            }
          });
      });
    },

    saveEmploymentInformation(data) {
      let payload = { ...data };

      /** if staff is married we allow saving children ,
       *  unless number of children is set to null
       * */

      payload.number_children = data.married ? data.number_children : null;
      return new Promise((resolve, reject) => {
        this.loading = true;

        user
          .createUpdateStaffEmploymentInfo(payload)
          .then(() => {
            this.finishDataActions();

            resolve(true);
          })
          .catch((err) => {
            if (err) {
              reject(true);
              this.finishDataActions();
            }
          });
      });
    },

    resetPassword(data) {
      const payload = {
        email: data.email,
      };

      this.$refs.confirm
        .show({
          title: "Reset User Password",
          message: "Are you sure you want to Reset user Password?",
          loading: this.loading,
        })
        .then((result) => {
          if (result) {
            user.reset_password(payload);
          } else {
            this.loading = false;
          }
        });
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },

    loadSalaryScalesData(params) {
      getSalaryScale(params).then((response) => {
        this.educationDesignations = response.data.data;
      });
    },

    loadDesignationsScalesData(params) {
      getSalaryScale(params).then((response) => {
        this.designationScales = response.data.data;
      });
    },

    getRoles(params) {
      fetchRoles({ ...params, size: 1000 }).then((response) => {
        this.roles = response.data[0].data;
      });
    },
    getGenderList() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "GENDER")
        .then((response) => {
          this.gender = response;
        });
    },

    kiwangoTaaluma() {
      this.$mixins.fetchByCategory("KIWANGO_CHA_TAALUMA").then((response) => {
        this.taaluma = response;
      });
    },

    kiwangoElimu() {
      this.$mixins.fetchByCategory("KIWANGO_CHA_ELIMU").then((response) => {
        this.elimu = response;
      });
    },

    staffCategory() {
      this.$mixins.fetchByCategory("STAFF_CATEGORIES").then((response) => {
        this.staffCategories = response;
      });
    },

    koziUalimu() {
      this.$mixins.fetchByCategory("KOZI_ZA_UALIMU").then((response) => {
        this.kozi = response;
      });
    },

    downloadTange() {
      let params = {
        ...this.filter,
      };

      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      user.standardTange(params).then((response) => {
        let fileUrl = window.URL.createObjectURL(response.data);
        let fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute(
          "download",
          "TANGE YA WALIMU-" +
            this.user.location.name.toUpperCase() +
            "-" +
            dateTime
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    downloadProgress() {
      let params = { ...this.filter };
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      schoolData(params).then((response) => {
        // console.log(response.data);
        let fileUrl = window.URL.createObjectURL(response.data);
        let fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute(
          "download",
          "PROGRESS -" + this.user.location.name.toUpperCase() + "-" + dateTime
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },
  created() {
    this.getRoles();
  },
  mounted() {
    this.vyuo();
    this.getGenderList();
    this.kiwangoTaaluma();
    this.kiwangoElimu();
    this.koziUalimu();
    this.getSubjects();
    this.getTeachingSubjects();
    this.staffCategory();

    this.getLevel();
    this.getAdminLevels();
    this.getSchoolLevels();
    this.getSchoolOwnership();
  },
};
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  /* font-weight: bold; */
  font-size: 11px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  /* font-weight: bold; */
  font-size: 11px;
}
</style>
<style>
.content-wrapper {
  display: flex;
}
.content-wrapper .summary {
  max-width: 20%;
}
.content-wrapper .all_data {
  min-width: 80%;
}

/*mobile*/
@media (min-width: 0) and (max-width: 425px) {
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
  .tablet-top,
  .tablet-middle,
  .tablet-bottom {
    display: flex;
    flex-direction: column;
  }
  /* .content-wrapper .summary {
    max-width: 50%;
  }
  .content-wrapper .all_data {
    max-width: 50%;
  } */

  .content-wrapper .summary {
    max-width: 20%;
  }
  .content-wrapper .all_data {
    min-width: 80%;
  }
}

/*tablet*/
@media (min-width: 426px) and (max-width: 767px) {
  .content-wrapper {
    display: flex;
    flex-direction: row;
  }

  .content-wrapper .summary {
    max-width: 20%;
  }
  .content-wrapper .all_data {
    min-width: 80%;
  }

  /* .content-wrapper .summary {
    max-width: 50%;
  }
  .content-wrapper .all_data {
    max-width: 50%;
  } */

  /* .grid {
    grid-template-areas:
      "summary"
      "all_data";
  } */

  .tablet-top,
  .tablet-middle,
  .tablet-bottom {
    display: flex;
    flex-direction: row;
  }
}

/*tablet*/
@media (min-width: 300px) and (max-width: 799px) {
  .content-wrapper {
    display: flex;
    flex-direction: row;
  }

  .content-wrapper .summary {
    max-width: 20%;
  }
  .content-wrapper .all_data {
    min-width: 80%;
  }

  /* .content-wrapper .summary {
    max-width: 50%;
  }
  .content-wrapper .all_data {
    max-width: 50%;
  } */

  /* .grid {
    grid-template-areas:
      "summary"
      "all_data";
  } */

  .tablet-top,
  .tablet-middle,
  .tablet-bottom {
    display: flex;
    flex-direction: row;
  }
}

/*Gallaxy Fold*/
@media (min-width: 280px) and (max-width: 653px) {
  .content-wrapper {
    display: flex;
    flex-direction: row;
  }

  .content-wrapper .summary {
    max-width: 20%;
  }
  .content-wrapper .all_data {
    min-width: 80%;
  }

  /* .content-wrapper .summary {
    max-width: 50%;
  }
  .content-wrapper .all_data {
    max-width: 50%;
  } */

  /* .grid {
    grid-template-areas:
      "summary"
      "all_data";
  } */

  .tablet-top,
  .tablet-middle,
  .tablet-bottom {
    display: flex;
    flex-direction: row;
  }
}

.summary {
  border-top: 5px solid #468dca !important;
  border-bottom: 1px solid #468dca !important;
  padding-bottom: 1px !important;
  box-shadow: 0 0px 0px 1px rgb(0 0 0 / 13%), 0 1px 0px 0 rgb(0 0 0 / 9%);
}
.all_data {
  border-top: 5px solid #468dca !important;
  border-bottom: 1px solid #468dca !important;
  padding-bottom: 1px !important;
  box-shadow: 0 0px 0px 1px rgb(0 0 0 / 13%), 0 1px 0px 0 rgb(0 0 0 / 9%);
}
</style>
