<template>
  <div class="perfornal-information">
    <div class="text--disabled">
      {{ tabTitle }}

      <hr style="width:200px" />
    </div>
    <v-container class="inputs-container">
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" lg="3" md="3" sm="12">
                <image-uploader
                  :width="100"
                  :height="100"
                  v-model="formData.photo"
                ></image-uploader>
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="12">
                <v-text-field
                  label="First Name"
                  v-model.trim="formData.first_name"
                  required
                  :rules="[isValid.required]"
                  v-uppercase
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="12">
                <v-text-field
                  label="Middle Name"
                  v-model.trim="formData.middle_name"
                  required
                  v-uppercase
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="12">
                <v-text-field
                  label="Surname"
                  v-model.trim="formData.surname"
                  required
                  :rules="[isValid.required]"
                  v-uppercase
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="12">
                <v-text-field
                  v-lowercase
                  label="Email"
                  v-model="formData.email"
                  type="email"
                  required
                  :rules="[isValid.required, isValid.existsEmail]"
                  :loading="isValidatingData"
                  @change.native="checkExistsEmails($event.target.value)"
                  :messages="existsEmailMessage"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="3" md="3" sm="12">
                <v-text-field
                  v-uppercase
                  label="Phone Number"
                  v-model="formData.phone_number"
                  required
                  v-mask="'####-###-###'"
                  :rules="[isValid.required, isValid.existsPhoneNumber]"
                  :loading="isValidatingData"
                  @change.native="checkExistsPhoneNumber($event.target.value)"
                  :messages="existsPhoneNumberMessage"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="2" md="2" sm="12">
                <v-select
                  label="Gender"
                  :items="genders"
                  :item-text="'name'"
                  v-model="formData.gender"
                  return-object
                  required
                  v-uppercase
                ></v-select>
              </v-col>

              <v-col cols="12" lg="3" md="3" sm="12">
                <v-autocomplete
                  label="staff Category"
                  :items="staffCategories"
                  :item-text="'name'"
                  v-model="formData.teaching_category"
                  return-object
                  required
                  v-uppercase
                  :rules="[isValid.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" lg="4" md="3" sm="12">
                <DatePicker
                  v-model="formData.birthdate"
                  :label="'Birth Date'"
                  :maxDate="$mixins.maxDate(21)"
                  :minDate="$mixins.minDate(60)"
                  :yearPicker="true"
                  :required="true"
                  :requiredMessage="'You must select date of birth'"
                />
              </v-col>

              <v-col cols="4" lg="4" md="3" sm="12">
                <v-text-field
                  label="Cheque Number"
                  v-model="formData.cheque_number"
                  required
                  :rules="[isValid.required, isValid.existsCheckNo]"
                  :loading="isValidatingData"
                  @change.native="checkExistsCheckNo($event.target.value)"
                  :messages="existsCheckNoMessage"
                  v-uppercase
                ></v-text-field>
              </v-col>

              <v-col cols="4" lg="4" md="3" sm="12">
                <v-text-field
                  label="NIDA ID"
                  v-model="formData.NIN"
                  v-mask="'########-#####-#####-##'"
                  required
                  :rules="[
                    isValid.required,
                    isValid.existsNida,
                    isValid.checkNidaLength,
                  ]"
                  :loading="isValidatingData"
                  @change.native="checkExistsNida($event.target.value)"
                  @input="validateNidaLength($event)"
                  :messages="existsNidaMessage"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <region-districts
                :input-region="formData.domicile_region"
                @input-region="formData.domicile_region = $event"
                :input-district="formData.domicile_district"
                @input-district="formData.domicile_district = $event"
                :requiredLga="true"
                :requiredLgaMessage="'You must select District of Domicile'"
                :requiredRegion="true"
                :requiredRegionMessage="'You must select Region of Domicile'"
              ></region-districts>

              <v-col cols="12" lg="4" md="4" sm="12">
                <v-text-field
                  label="Permanent  Address"
                  v-model="formData.physical_address"
                  @input="formData.physical_address = $event.toUpperCase()"
                  rows="2"
                  prepend-icon="mdi-comment"
                  :rules="[isValid.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <dual-selects
                  :items="roles"
                  :title="'Select Roles'"
                  :label="'Filter Roles...'"
                  :columnName="'name'"
                  :filter-function="search"
                  v-model="formData.roles"
                  :is-open="dialog"
                  :is-required="true"
                  :is-required-message="'You must choose roles'"
                />
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-radio-group v-model="formData.married" row>
                  <template v-slot:label>
                    <div><strong>Married?</strong></div>
                  </template>
                  <v-radio :value="true">
                    <template v-slot:label>
                      <div>
                        <strong class="success--text">Yes</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <div>
                        <strong class="primary--text">No</strong>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2" v-if="formData.married">
                <v-text-field
                  label="Number of Children, if any"
                  v-model="formData.number_children"
                  required
                  :rules="[isValid.required]"
                  v-if="formData.married"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" v-if="!isHOS">
                <eExamTree
                  :setActive="setActive"
                  :selected="selected"
                  :location="formData.location"
                  @location="formData.location = $event"
                />
              </v-col>
            </v-row>
            <v-card-actions class="md-8">
              <!-- <v-btn
                v-if="activeStep > 1"
                color="primary"
                @click="previous(0)"
                :disabled="activeStep === 0"
              >
                Previous
              </v-btn> -->

              <v-spacer></v-spacer>
              <v-btn
                tile
                color="primary"
                :disabled="!valid"
                @click="add"
                :loading="loading"
              >
                <v-icon left>mdi-forward</v-icon>
                Continue</v-btn
              >
            </v-card-actions>
          </v-form>
          <!-- <pre> location:{{ formData.location }}</pre> -->
        </v-container>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import ImageUploader from "../../../shared/ImageUploader.vue";
export default {
  components: { ImageUploader },

  props: {
    staffCategories: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },

    dialog: {
      type: Boolean,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
    },

    searchOptions: {
      type: Function,
      required: true,
    },

    save: {
      type: Function,
      required: true,
    },
    genders: {
      type: Array,
      required: true,
    },

    tabTitle: {
      type: String,
      required: false,
    },

    validated: {
      type: Function,
      required: false,
    },

    activeStep: {
      type: Number,
      required: true,
    },

    next: {
      type: Function,
      required: true,
    },

    previous: {
      type: Function,
      required: true,
    },
  },

  name: "StaffPersonalInformation",
  data: () => ({
    valid: true,
    base64Image: "",
    existsEmailMessage: "",
    existsCheckNoMessage: "",
    existsNidaMessage: "",
    existsPhoneNumberMessage: "",
    isValidating: false,
    EXISTS_API: "/api/v1/users/exists",
    isValid: {
      name: (v) => !!v || " Name required",
      required: (v) => !!v || " Field required",
      /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',

      existsEmail: () => true || this.existsEmailMessage,
      existsCheckNo: () => true || this.existsCheckNoMessage,
      existsNida: () => true || this.existsNidaMessage,
      existsPhoneNumber: () => true || this.existsPhoneNumberMessage,
      checkNidaLength: () => true || "NIDA ID must be 20 characters",
    },
    location: {},
  }),
  methods: {
    add() {
      // this.formData.location =
      //   this.isHOS === true ? this.user.location : this.selected;

      let payload = { ...this.formData };
      payload.completion_percentage = 34;
      payload.phone_number = this.formData.phone_number
        .toString()
        .replace(/-/g, "");
      payload.NIN = this.formData.NIN.toString().replace(/-/g, "");
      payload.photo = this.formData?.photo
        ? this.formData.photo.toString().split(",")[1]
        : null;

      this.save(payload);
    },

    close() {
      this.closeDialog();
      this.location = {};
    },
    search(data) {
      this.searchRoles(data);
    },
    setActive(selection) {
      //console.log('selected', selection)
      this.location = selection.length > 0 ? selection[0] : { name: null };
      this.formData.location = selection[0];
    },

    async checkExistsEmails(data) {
      this.isValidating = true;
      let payload = {
        email: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsEmail = false;
          this.existsEmailMessage = `Email address already taken at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsEmail = true;
          this.existsEmailMessage = "";
        }
      });
    },

    async checkExistsPhoneNumber(data) {
      this.isValidating = true;
      let payload = {
        phone_number: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsPhoneNumber = false;
          this.existsEmailMessage = `Phone Number already in use at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsPhoneNumber = true;
          this.existsPhoneNumberMessage = "";
        }
      });
    },

    async checkExistsCheckNo(data) {
      this.isValidating = true;
      let payload = {
        cheque_number: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsCheckNo = false;
          this.existsCheckNoMessage = `Cheque Number already in use at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsCheckNo = true;
          this.existsCheckNoMessage = "";
        }
      });
    },

    async checkExistsNida(data) {
      this.isValidating = true;
      let payload = {
        nida: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsNida = false;
          this.existsNidaMessage = `NIDA ID already in use at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsNida = true;
          this.existsNidaMessage = "";
        }
      });
    },

    validateNidaLength(data) {
      var str = data.replace(/-/g, "");

      if (str.length === 20) {
        this.isValid.checkNidaLength = true;
      } else {
        this.isValid.checkNidaLength = false;
      }
    },
  },
  computed: {
    title() {
      return this.formData.staff_id ? "Update" : "Create";
    },
    selected() {
      return this.formData &&
        this.formData.location &&
        Object.keys(this.location).length === 0
        ? this.formData.location
        : this.location;
    },

    isHOS() {
      return this.user?.location?.level?.id === 5 ? true : false;
    },

    isValidatingData() {
      return this.isValidating ? true : false;
    },

    loggedInUser() {
      return this.$store.getters.getCurrentUser;
    },
  },
  mounted() {},
  watch: {
    valid(oldValue, newValue) {
      this.validated(newValue);
    },
  },
};
</script>

<style scoped>
.no-scroll {
  overflow: hidden;
}
</style>
