<template>
  <v-card class="d-flex no-wrap " color="grey lighten-2" flat tile>
    <v-layout row wrap color="grey lighten-2" flat tile>
      <v-flex xs12 color="grey lighten-2 pr-3" flat>
        <v-card outlined flat class="ma-5 order-1 pa-3">
          <v-label>
            <p
              class="subtitle-2 text-center primary--text font-weight-bold mt-5"
            >
              Employment Information
            </p>
          </v-label>

          <v-divider class="divider"></v-divider>
          <v-simple-table fixed-header class="pb-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="primary--text"><b>Personal File Number</b></td>
                  <td>{{ staff.file_no }}</td>
                </tr>

                <tr>
                  <td class="primary--text"><b>TSC Number </b></td>
                  <td>{{ staff.tsc_no }}</td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Cheque Number </b></td>
                  <td>{{ staff.cheque_number }}</td>
                </tr>
                <tr>
                  <td class="primary--text"><b>Appointment Date</b></td>
                  <td>{{ staff.appointment_date }}</td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Approval Date</b></td>
                  <td>
                    {{ staff.approval_date }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Appointment Designation </b></td>
                  <td>
                    {{ staff.appointment_designation.title_en }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Current Designation </b></td>
                  <td>
                    {{
                      staff.current_designation &&
                        staff.current_designation.title_en
                    }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Last Promotion Date </b></td>
                  <td>
                    {{ staff.last_promotion_date }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Salary Scale </b></td>
                  <td>
                    {{ staff.salary_scale && staff.salary_scale.scale
                    }}{{ staff.salary_step && staff.salary_step.name }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Staff Teaching Category </b></td>
                  <td>
                    {{ staff.staff_category && staff.staff_category.name }}
                  </td>
                </tr>

                <tr>
                  <td class="primary--text"><b>Retirement Date </b></td>
                  <td>
                    {{ staff.retire_date }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  name: "StaffEmploymentInformation",
  props: {
    staff: { type: Object },
  },
};
</script>
