<template>
  <div class="perfornal-information">
    <v-card-text>
      <div class="text--disabled">
        {{ tabTitle }}

        <hr style="width:200px" />
      </div>
      <v-container>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field
                label="Form IV Index No. Eg. S.0508.0042.2006"
                v-model="formData.form_iv_index"
                required
                :rules="[isValid.required, isValid.checkFIVLength]"
                :loading="isValidatingData"
                :messages="existsFIVMessage"
                v-mask="'A.####.####.####'"
                counter
                v-uppercase
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field
                label="Form VI Index No. Eg. S.0519.0012.2009"
                v-model="formData.form_vi_index"
                required
                :rules="[isValid.required, isValid.checkFVILength]"
                :loading="isValidatingData"
                :messages="existsFVIMessage"
                v-mask="'A.####.####.####'"
                counter
                v-uppercase
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-select
                label="Kiwango cha Elimu"
                :items="elimu"
                :item-text="'name'"
                v-model="formData.education_level"
                return-object
                required
                v-uppercase
                :rules="[isValid.required]"
              ></v-select>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12">
              <v-autocomplete
                label="Kiwango cha Taaluma"
                :items="taaluma"
                :item-text="'name'"
                v-model="formData.professional_level"
                return-object
                required
                v-uppercase
                :rules="[isValid.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="5" md="5" sm="12">
              <v-autocomplete
                label="Graduated University "
                :items="universities"
                :item-text="'name'"
                v-model="formData.university"
                return-object
                required
                :rules="[isValid.required]"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="12">
              <v-autocomplete
                label="Kozi aliyocho hitimu "
                :items="kozi"
                :item-text="'name'"
                v-model="formData.professional_course"
                return-object
                required
                :rules="[isValid.required]"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="12">
              <DatePicker
                v-model="formData.graduation_year"
                :label="'Graduation Year'"
                :maxDate="$mixins.maxDate(0)"
                :minDate="$mixins.minDate(60)"
                :yearPicker="true"
                :yearOnly="true"
                :rules="[isValid.required]"
                :required="true"
                :requiredMessage="'You must select graduation Year'"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <DualSelects
                :items="masomo"
                :title="'Select Professional Subjects'"
                :label="'Filter Professional ...'"
                :columnName="'name'"
                :filter-function="searchProffessionalSubjects"
                v-model="formData.proffesional_subjects"
                :is-open="dialog"
                :icon="'mdi-book-open-variant'"
                :is-required="true"
                :is-required-message="
                  'You must choose Proffesional Subjects and not more than 2 subjects'
                "
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <DualSelects
                :items="masomoKufundishia"
                :title="'Select Teaching Subjects'"
                :label="'Filter Teaching Subjects...'"
                :columnName="'name'"
                :filter-function="searchTeachingSubjects"
                v-model="formData.teaching_subjects"
                :is-open="dialog"
                :icon="'mdi-book-open-page-variant'"
                :is-required="true"
                :is-required-message="'Please choose teaching Subjects'"
              />
            </v-col>
          </v-row>

          <v-card-actions class="md-8">
            <v-btn
              tile
              color="primary"
              @click="previous(1)"
              :disabled="activeStep === 0"
            >
              <v-icon left>mdi-skip-backward</v-icon>
              Previous</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              tile
              color="primary"
              :disabled="!isValidated"
              @click="add"
              :loading="loading"
            >
              <v-icon left>mdi-forward</v-icon>
              Continue</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      required: false,
    },

    dialog: {
      type: Boolean,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
    },
    searchOptions: {
      type: Function,
      required: true,
    },

    save: {
      type: Function,
      required: true,
    },
    genders: {
      type: Array,
      required: true,
    },

    universities: {
      type: Array,
      required: true,
    },

    tabTitle: {
      type: String,
      required: false,
    },

    validated: {
      type: Function,
      required: false,
    },

    searchTeachingSubjects: {
      type: Function,
      required: true,
    },

    searchProffessionalSubjects: {
      type: Function,
      required: false,
    },

    universities: {
      type: Array,
      required: false,
    },

    masomo: {
      type: Array,
      required: false,
    },

    masomoKufundishia: {
      type: Array,
      required: false,
    },

    elimu: {
      type: Array,
      required: false,
    },

    taaluma: {
      type: Array,
      required: false,
    },

    kozi: {
      type: Array,
      required: false,
    },

    activeStep: {
      type: Number,
      required: true,
    },

    next: {
      type: Function,
      required: true,
    },

    previous: {
      type: Function,
      required: true,
    },
  },

  name: "StaffEducationInformation",
  data: () => ({
    valid: true,
    base64Image: "",
    existsFIVMessage: "",
    existsFVIMessage: "",
    existsNidaMessage: "",
    isValidating: false,
    EXISTS_API: "/api/v1/users/exists",
    isValid: {
      name: (v) => !!v || " Name required",
      nida: (v) => !!v || " NIDA ID required",
      required: (v) => !!v || " Field required",
      /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',

      existsFIV: () => true || this.existsFIVMessage,
      existsFVI: () => true || this.existsFVIMessage,
      existsNida: () => true || this.existsNidaMessage,
      checkFIVLength: (v) =>
        (v && v.length >= 16) ||
        "Check FIV index no. follow this format S.XXXX.YYYY.ZZZZ",
      checkFVILength: (v) =>
        (v && v.length >= 16) ||
        "Check FIV indexo. follow this format S.XXXX.YYYY.ZZZZ",
    },
    location: {},
  }),

  methods: {
    add() {
      let payload = { ...this.formData };
      payload.completion_percentage = 33;
      this.save(payload);
    },

    close() {
      this.closeDialog();
      this.location = {};
    },

    setActive(selection) {
      //console.log('selected', selection)
      this.location = selection.length > 0 ? selection[0] : { name: null };
    },

    async checkExistsFVI(data) {
      this.isValidating = true;
      let payload = {
        form_vi_index: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsFVI = false;
          this.existsFVIMessage = `Form VI index number already taken at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsFVI = true;
          this.existsFVIMessage = "";
        }
      });
    },

    async checkExistsFIV(data) {
      this.isValidating = true;
      let payload = {
        form_iv_index: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.existsFVIMessage = `Form IV index number already in use at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsFIV = true;
          this.existsFVIMessage = "";
        }
      });
    },
    async checkExistsNida(data) {
      this.isValidating = true;
      let payload = {
        nida: data.toLowerCase(),
        id: this.formData && this.formData.staff_id,
      };
      this.$mixins.checkExists(this.EXISTS_API, payload).then((response) => {
        this.isValidating = false;
        if (response.exists) {
          this.isValid.existsNida = false;
          this.existsNidaMessage = `NIDA ID already in use at ${response.response?.location?.name}`;
        } else {
          this.isValid.existsNida = true;
          this.existsNidaMessage = "";
        }
      });
    },

    allowedIndex() {
      let allowedChars = ["P", "S"];
    },
  },
  computed: {
    isValidated() {
      return (
        this.valid &&
        this.formData.proffesional_subjects?.length <= 2 &&
        this.formData.proffesional_subjects?.length >= 1 &&
        this.formData.teaching_subjects.length !== 0
      );
    },
    title() {
      return this.formData.id ? "Update" : "Create";
    },
    selected() {
      return this.formData &&
        this.formData.location &&
        Object.keys(this.location).length === 0
        ? this.formData.location
        : this.location;
    },

    isHOS() {
      return this.user?.location?.level?.id === 5 ? true : false;
    },

    isValidatingData() {
      return this.isValidating ? true : false;
    },
  },
  mounted() {},
  watch: {
    valid(oldValue, newValue) {
      this.validated(newValue);
    },
  },
};
</script>

<style scoped>
.no-scroll {
  overflow: hidden;
}
</style>
