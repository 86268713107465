import axios from "axios";
const ENDPOINT = "/api/v1/users";
const STAFF_EDUCATION_ENDPOINT = "/api/v1/users/staff/education";
const STAFF_EMPLOYMENT_ENDPOINT = "/api/v1/users/staff/employment";

export const user = {


  async staff(payload) {

    return axios.get(ENDPOINT + '/staff', { params: payload });
  },



  async get(payload) {
    return axios.get(ENDPOINT, { params: payload });
  },



  async exists(payload) {
    return axios.get(ENDPOINT + '/exists', { params: payload });
  },

  async create(payload) {
    return await axios.post(ENDPOINT, payload);
  },

  async update(payload) {
    return await axios.put(ENDPOINT, payload);
  },


  async createUpdateStaffEducationInfo(payload) {
    return await axios.post(STAFF_EDUCATION_ENDPOINT, payload);
  },


  async createUpdateStaffEmploymentInfo(payload) {
    return await axios.post(STAFF_EMPLOYMENT_ENDPOINT, payload);
  },


  async remove(payload) {
    return axios.delete(ENDPOINT + "/" + payload);
  },

  async reset_password(payload) {
    return axios.post(ENDPOINT + "/reset-password", payload);
  },


  async changeDefaultPassword(payload) {
    return axios.post(ENDPOINT + "/change-default-password", payload);
  },

  async standardTange(params) {
    return axios.get(ENDPOINT + "/get-standard-tange",
      { params: params, responseType: 'blob' }
    );
  },

}

